// import 'vite/modulepreload-polyfill'
import alpineUI from '@alpinejs/ui'
import Alpine from 'alpinejs'
import focus from '@alpinejs/focus'
import collapse from '@alpinejs/collapse' 
import intersect from '@alpinejs/intersect'
import persist from '@alpinejs/persist'
import fade from './alpine-components/_fade.js'

import cookieStorage from './partials/_cookie-storage';

import Player from '@vimeo/player'
import Splide from '@splidejs/splide';

import '../css/app.css'
import '@splidejs/splide/css/core'

alpineUI(Alpine)
 
Alpine.plugin(focus)
Alpine.plugin(collapse)
Alpine.plugin(intersect)
Alpine.plugin(persist)
Alpine.plugin(fade);

window.Alpine = Alpine
window.Vimeo = Player
window.Splide = Splide

Alpine.store('header', {
    init() {
        this.setNavStatus()
    },

    stuck: false,

    open: false,

    toggleable: false,

    toggleNav() {
        this.open = ! this.open
    },

    setNavStatus() {
        this.toggleable = ! window.matchMedia('(min-width: 768px)').matches;
        this.open = window.matchMedia('(min-width: 768px)').matches;
    }
});
 
Alpine.start()